<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('external_sidebar.trainee_attendance_sheet') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.fiscal_year')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.fiscal_year_id"
                          :options="fiscalYearList"
                          id="fiscal_year_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <!-- <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}}
                        </template>
                        <v-select name="organization"
                          v-model="search.fiscal_year_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= fiscalYearList
                          :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col> -->
                    <!-- <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('elearning_config.organization')"
                          label-for="organization"
                      >
                        <v-select name="organization"
                            v-model="search.org_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= orgList
                            :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col> -->
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_type_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_type')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_type_id"
                          :options="trainingTypeList"
                          id="training_type_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_category_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_category')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_category_id"
                            :options="trainingCategoryList"
                            id="training_category_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_title_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_title')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_title_id"
                            :options="trainingTitleList"
                            id="training_title_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="circular_memo_no"
                      >
                      <template v-slot:label>
                        {{$t('elearning_iabm.circular_memo_no')}}
                      </template>
                        <b-form-select
                          plain
                          v-model="search.circular_memo_no"
                          :options="circularList"
                          id="circular_memo_no"
                          >
                          <template v-slot:first>
                              <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="batch_no"
                        >
                          <template v-slot:label>
                            {{$t('elearning_iabm.batch_no')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                              plain
                              v-model="search.batch_no"
                              :options="batchList"
                              id="batch_no"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value=0>{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                    </b-col>
                    <!-- <b-col lg="6" sm="6">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="batch_no"
                      >
                      <template v-slot:label>
                        {{$t('elearning_iabm.batch_no')}}
                      </template>
                        <b-form-select
                          plain
                          v-model="search.batch_no"
                          :options="batchList"
                          id="batch_no"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col> -->
                    <b-col lg="12" sm="12" class="text-right">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('external_sidebar.trainee_attendance_sheet') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <!-- <b-button variant="primary" @click="pdfExportList" class="mr-2">
                            {{  $t('globalTrans.export_pdf') }}
                        </b-button> -->
                        <!-- <router-link to="attendance-form" :class="'btn btn_add_new font-weight-bold'"><i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}</router-link> -->
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                          <span class="badge badge-info" v-if="data.item.status == 1">{{$t('globalTrans.save')}}</span>
                                          <span class="badge badge-success" v-else>{{$t('globalTrans.finalSave')}}</span>
                                        </template>
                                        <template v-slot:cell(training_date)="data">
                                          <span class="capitalize">{{ data.item.training_date | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(start_time)="data">
                                          <span class="capitalize">{{ data.item.start_time | time12FormateTraining }}</span>
                                        </template>
                                        <template v-slot:cell(end_time)="data">
                                          <span class="capitalize">{{ data.item.end_time | time12FormateTraining }}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <router-link :to="`attendance-details?circularMemoNo=${data.item.circular_memo_no}&batchNo=${data.item.batch_no}`" :title="$t('elearning_tim.attendance') + ' ' + $t('globalTrans.details')" :class="'btn_table_action table_action_view'"><i class="fas fa-eye"></i></router-link>
                                            <router-link :to="`attendance-form?circularMemoNo=${data.item.circular_memo_no}&batchNo=${data.item.batch_no}`" :title="$t('elearning_tim.attendance') + ' ' + $t('globalTrans.entry')" :class="'btn_table_action table_action_view'"><i class="fas fa-calendar-check"></i></router-link>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="$t('external_sidebar.trainee_attendance_sheet') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <b-button variant="primary" @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
            <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
          </b-button>
          <Details :id="editItemId" :key="editItemId"  ref="details"/>
      </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { attendanceList, userCircularList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Store from '@/store'
import ExportPdf from './export_pdf_list'
import Details from './Details'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details
    },
    data () {
        return {
          // loading: false,
          circularLoading: false,
          search: {
            circular_memo_no: '',
            org_id: this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg,
            training_type_id: 0,
            training_category_id: 0,
            training_title_id: 0,
            fiscal_year_id: 0,
            batch_no: 0
          },
          trainingCategoryList: [],
          trainingTitleList: [],
          circularList: [],
          allBatchListData: [],
          batchList: [],
          allData: []
        }
    },
    computed: {
      allBatchList () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' }
        ]
      },
      trainingTypeList: function () {
        return this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTypeList.filter(item => item.status === 1 && item.org_id === parseInt(this.search.org_id))
      },
      // fiscalYearList: function () {
      //   return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
      // },
      fiscalYearList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0).map(obj => {
              if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
              } else {
              return { value: obj.value, text: obj.text_en }
              }
          })
      },
      orgList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
        return this.$store.state.ExternalUserIrrigation.traineeTranerObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
              { label: this.$t('elearning_config.organization'), class: 'text-center' },
              { label: this.$t('elearning_config.training_title'), class: 'text-center' },
              // { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-center' },
              { label: this.$t('elearning_iabm.batch_no'), class: 'text-center' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'index' },
              { key: 'org_bn' },
              { key: 'training_title_bn' },
              // { key: 'circular_memo_no' },
              { key: 'batch_bn' },
              { key: 'action' }
              ]
          } else {
              keys = [
                { key: 'index' },
                { key: 'org' },
                { key: 'training_title' },
                // { key: 'circular_memo_no' },
                { key: 'batch' },
                { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
        this.search = Object.assign({}, this.search, {
            // org_id: this.$store.state.dataFilters.orgId,
            // fiscal_year_id: this.$store.state.ExternalUserTraining.currentFiscalYearId
            fiscal_year_id: this.getCurrentFiscalYearId(this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0))
        })
        this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      },
      'search.training_title_id': function (newValue) {
        this.getCircularList()
      },
      'search.circular_memo_no': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const batchData = this.allBatchListData.filter(allBatchList => String(allBatchList.circular_memo_no) === String(newVal))
          const batchList = []
          batchData.map((obj, index) => {
            if (obj.batch_no) {
              const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
              if (typeof isThere !== 'undefined') {
                batchList.push(isThere)
              }
            }
          })
          this.batchList = batchList
          if (this.batchList.length === 1) {
            this.search.batch_no = 1
          }
        }
      }
    },
    methods: {
      getCurrentFiscalYearId (fiscalYearList) {
          if (fiscalYearList.length === 0) {
            return
          }
          const currentDate = new Date()
          const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
          /** Define the year position  */
          const yearPosition = month < 7 ? 5 : 0
          const yearStr = `${year}`
          let currentFiscalYearId = 0
          fiscalYearList.forEach(element => {
            if (element.text_en.indexOf(yearStr) === yearPosition) {
              currentFiscalYearId = element.value
            }
          })
          return currentFiscalYearId
      },
      finalSave (item) {
        // this.toggleStatusCustom2(trainingElearningServiceBaseUrl, trainingScheduleFinalSave, item)
      },
      toggleStatusCustom2 (baseUrl, uri, item) {
        window.vm.$swal({
          title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatusCustom(baseUrl, uri, item)
          }
        })
      },
      changeStatusCustom (baseUrl, uri, item) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
          if (response.success) {
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      remove (item) {
          this.changeStatus(trainingElearningServiceBaseUrl, item, 'training_e_learning', 'trainerEvaluationList')
      },
      async getCircularList () {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.search.fiscal_year_id,
          training_type_id: this.search.training_type_id,
          training_category_id: this.search.training_category_id,
          training_title_id: this.search.training_title_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, userCircularList, serchData)
        if (!result.success) {
          this.circularList = []
          this.allBatchListData = []
        } else {
          this.allBatchListData = result.batchList
          const listObject = result.data
          const tmpList = listObject.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            } else {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            }
          })
          this.circularList = tmpList
        }
        this.circularLoading = false
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, attendanceList, params).then(response => {
              if (response.success) {
                this.$store.dispatch('setList', this.getCustomDataList(response.data))
                this.allData = response.data.data
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          const courseObj = this.$store.state.ExternalUserTraining.traineeTranerObj.courseList.find(doc => doc.value === parseInt(item.course_id))
          const courseData = {}
          if (typeof courseObj !== 'undefined') {
            courseData.course_name = courseObj.text_en
            courseData.course_name_bn = courseObj.text_bn
          } else {
            courseData.course_name = ''
            courseData.course_name_bn = ''
          }
          const courseModuleObj = this.$store.state.ExternalUserTraining.traineeTranerObj.courseModuleList.find(doc => doc.value === parseInt(item.course_module_id))
          const courseModuleData = {}
          if (typeof courseModuleObj !== 'undefined') {
            courseModuleData.course_module_name = courseModuleObj.text_en
            courseModuleData.course_module_name_bn = courseModuleObj.text_bn
          } else {
            courseModuleData.courseModule_name = ''
            courseModuleData.courseModule_name_bn = ''
          }
          const batchObj = this.allBatchList.find(doc => doc.value === parseInt(item.batch_no))
          const batchData = {}
          if (typeof batchObj !== 'undefined') {
            batchData.batch = batchObj.text_en
            batchData.batch_bn = batchObj.text_bn
          } else {
            batchData.batch = ''
            batchData.batch_bn = ''
          }
          return Object.assign({}, item, batchData, orgData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, courseData, courseModuleData)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      },
      pdfExportList () {
        const reportTitle = this.$i18n.locale === 'en' ? 'Traning Schedule List' : 'প্রশিক্ষণের সময়সূচী তালিকা'
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this, this.allData)
      }
    }
}
</script>
